<template>
  <div class="">

    <div
      v-if="guid"
      class="mt70"
      style="margin-bottom: 60px"
    >
      <ul
        class="flex"
        style="margin-top: 30px; margin-bottom: 20px"
      >
        <li
          v-for="(tab,index) in estateTypes"
          :key="tab.value"
          @click="getHouseTypeDetail(tab.guid,index)"
          class="tab-item tab-item-buildingArea size15 cursor"
          :class="{ active: guid==tab.guid }"
          style="padding-top:4px;padding-bottom:4px;"
        >
          <span>{{ "建面" + tab.buildingArea +'㎡' }}</span>
        </li>
      </ul>

      <div class="houseType-warp row ">
        <transition name="show">
          <div
            class="flex1 houseType-img row c_center"
            v-if="guid == data.guid "
          >
            <img
              v-if="data.coverImage"
              :src="data.coverImage"
              alt=""
            >
            <!-- <div></div> -->
          </div>
        </transition>

        <div
          class="pic-info"
          style="width:400px;margin-left:70px"
        >
          <div
            class="inner-wrapper"
            v-if="data.guid"
          >
            <div class="frame-name-wrapper ">
              <div class="name-left row s_center">
                <span class="name">{{data.room+'室'+data.hall+'厅'+data.bathRoom+'卫'}}</span>
                <span class="status">{{data.sellStateDesc}}</span>
              </div>
              <div v-show="!isSourceERP"
                @click="isShowQRCode = true"
                class="name-right comparison"
              >一键咨询首付</div>
            </div>
            <div class="frame-price-wrapper ">
              <div class="price-left">
                <span class="price">约{{data.price}}</span>
                <span class="price-status">万/套(参考价格）</span>
                <span
                  @click="isShowQRCode = true"
                  class="change-price changePrice-remind mod-banner"
                >变价提醒</span>
              </div>
              <div
                @click="isShowQRCode = true"
                class="price-right"
              >
                <i class="el-icon-star-off size16 black"></i>
                <div class="text">关注</div>
              </div>
            </div>

            <ul
              v-if="data.tags&&data.tags.length>0"
              class="frame-tag-wrapper"
            >
              <li
                v-for="(tag,index) in data.tags"
                :key="index"
                class="tag"
              >{{tag}}</li>
            </ul>

            <ul class="base-info-wrapper">
              <li class="part">
                <span class="key">面积</span>
                <span class="value">建面 {{data.buildingArea}}m²</span>
              </li>
              <li class="part">
                <span class="key">物业类型</span>
                <span class="value">{{data.propertyTypeDesc}}</span>
              </li>
              <li class="part">
                <span class="key">朝向</span>
                <span class="value">{{data.orientationDesc}}</span>
              </li>

              <li class="sub-info">
                <span class="sub-key">户型分布</span>

                <span class="text">{{bindModel}} </span>
              </li>
            </ul>

            <ul class="sub-info-wrapper">
              <li class="sub-info">
                <span class="sub-key">户型介绍</span>
                <span class="text">{{data.introduction||'暂无介绍'}}</span>
              </li>
              <li class="sub-info">
                <span class="sub-key">户型优势</span>
                <span class="text">{{data.brightSpot||'暂无数据'}}</span>
              </li>
            </ul>

            <div class="agent-wrapper"></div>

          </div>

          <div class="mt10">
            <div
              v-if="agentData.guid"
              class="row s_start info-agent"
            >
              <img
                class="agent-avatar"
                :src="agentData.avatar?agentData.avatar:defaultAvatar"
                alt=""
              />
              <div class="ml20 column flex1">
                <div class="row s_center">
                  <span class="agent-name size24">{{
                    agentData.trueName
                    }}</span>
                  <span class="agent-tag size12 ml10">新房顾问</span>
                </div>

                <p
                  class="size14 pt5"
                  style="color: #cccccc"
                >
                  {{ agentData.departmentName }}
                </p>
                <p
                  class="size16 "
                  style="color: #fe615a; font-weight: bold"
                  v-show="agentData.fixTel || agentData.mobile"
                >
                  {{isSourceERP? agentData.mobile :agentData.fixTel || agentData.mobile }}
                </p>
                <div>
                  <el-button
                    style="width: 112px; box-sizing: border-box; height: 42px;padding:0;line-height: 40px;text-align: center;"
                    @click="isShowQRCode = true"
                    plain
                    class="tButton chatButton greenBG green size16"
                  >
                    <i class="el-icon-chat-dot-round"></i>
                    在线咨询
                  </el-button>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>

      <div class="disclaimer mt20">
        <p class="det-disclaimer">
          <span>免责声明：</span>
          <span>本网呈现的户型图为第三方提供的非标准的参考户型图或根据第三方提供的户型图通过系统/技术等手段生成的非标准的参考户型图，不应作为您交易的决策依据。户型图上呈现的户型、面积、朝向、内部结构、功能分区仅供展示参考，实际信息请以与开发商签订的商品房买卖合同为准。</span>
          <span>详细请阅读</span>
          <span
            class="green cupo"
            @click="isShowRiskTip = true"
          >《重要风险提示》</span>
          <span>。</span>
        </p>
      </div>
    </div>

    <div
      v-else
      id="house-type"
      class="mt70"
      style="margin-bottom: 60px"
    >
      <!-- <h1 class="size28 clo4d bold mb20">户型介绍</h1> -->
      <ul
        class="flex"
        style="margin-top: 30px; margin-bottom: 20px"
      >
        <li
          v-for="tab in houseTypeTabs"
          :key="tab.value"
          @click="getHouseTypes(tab.value)"
          class="tab-item size15 cursor"
          :class="{ active: tab.choose }"
        >
          <span>{{ tab.name + "(" + tab.total + ")" }}</span>
          <!-- <div
            class="tab-item-underline mt15"
            :class="{ greenBG: tab.choose }"
          ></div> -->
        </li>
      </ul>
      <ul class="estate-box">
        <li
          class="estate-item"
          v-for="(estate,index) in estateTypes"
          :key="index"
          @click="getHouseTypeDetailToTab(estate.guid)"
        >
          <div
            class="cover-box"
            style="height: 232px"
          >
            <img
              class="all"
              :src="estate.coverImage"
              alt=""
            />
            <i class="hxt-mask"></i>
          </div>
          <div class="estate-item-footer">
            <div
              class="row s_center"
              style="width:100%"
            >
              <div class="row size18 col4d flex1">
                <p
                  class="flex1"
                  style="font-size: 16px; font-weight: 600; color: #333"
                >
                  居室：
                  <span v-if="estate.room">{{ estate.room + "室" }}</span>
                  <span v-if="estate.hall">{{ estate.hall + "厅" }}</span>
                  <span v-if="estate.bathRoom">{{
                    estate.bathRoom + "卫"
                    }}</span>
                </p>
                <p
                  class="size16"
                  v-if="estate.price"
                >
                  约 <span class="red bold">{{ estate.price }}</span> 万/套
                </p>
                <p
                  class="red bold size16"
                  v-else
                >价格待定</p>
              </div>
            </div>
            <div class="row s_center">
              <p
                class="flex1 mv5"
                style="font-size: 16px; font-weight: 600; color: #333"
              >
                建面：{{ estate.buildingArea }}㎡
              </p>
              <div
                class="tag size12 white ml5"
                :class="estate.sellStateDesc == '在售' ? 'greenBG' : 'redBG'"
                style="height: 20px; line-height: 20px; padding: 0 5px"
              >
                {{ estate.sellStateDesc }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <qr-code-dialog
      :show-code="isShowQRCode"
      :page="page"
      :houseGuid="guid"
      @update-show-code="isShowQRCode = $event"
    />
    <risk-tip
      :show-risk-tip="isShowRiskTip"
      @update-show-risk-tip="isShowRiskTip = $event"
    />
  </div>
</template>

<script>
import newEstateService from "../../../services/newEstateService";
import Dialog from "../../../components/Dialog.vue";
import RiskTip from "../../../components/RiskTip.vue";
import Constant from "../../../common/Constant.js";
import { mapState } from "vuex";
export default {
  props: ["estateGuid"],
  components: {
    QrCodeDialog: Dialog,
    RiskTip,
  },
  data() {
    return {
      houseTypeTabs: [],
      estateTypes: [],
      isDetail: false,
      guid: "",
      data: {},
      bindModel: [],
      isShowQRCode: false,
      page: 2,

      rcommendAgent: [],
      agentData: {},

      isShowRiskTip: false,
      building: [],
      defaultAvatar: Constant.DEFAULT_AVATAR,
    };
  },
  methods: {
    async getHouseTypes(room) {
      let param = {
        estateGuid: this.estateGuid,
        propertyType: 2,
      };
      if (room) {
        param.room = room;
      }
      const { data } = await newEstateService.getHouseTypes(param);
      let houseTypes = data;
      let houseTypeTabs = [];
      if (houseTypes) {
        //筛选条件只执行一次
        if (this.houseTypeTabs.length === 0) {
          for (let index = 0; index < houseTypes.houseType.length; index++) {
            let houseTypeParam = {
              name: "",
              value: "1",
              total: "0",
              choose: false,
            };

            if (houseTypes.houseType[index].name == 0) {
              houseTypeParam.name = "全部";
              houseTypeParam.value = "";
            } else {
              houseTypeParam.name = houseTypes.houseType[index].name + "居室";
              houseTypeParam.value = houseTypes.houseType[index].name;
            }
            houseTypeParam.total = houseTypes.houseType[index].value;
            if (index === 0) {
              houseTypeParam.choose = true;
              // this.houseTypeValue = houseTypeParam.value;
            }
            houseTypeTabs.push(houseTypeParam);
          }
          this.houseTypeTabs = houseTypeTabs;
          // console.log('this.houseTypeTabs', this.houseTypeTabs)
        }

        if (houseTypes.realHouseEstateHouseType.length > 0) {
          this.estateTypes = houseTypes.realHouseEstateHouseType;
        }
        if (room) {
          this.houseTypeTabs.forEach((item) => {
            if (item.value == room) {
              item.choose = true;
            } else {
              item.choose = false;
            }
          });
        }
      }
    },
    async getHouseTypeDetail(guid, index) {
      this.guid = guid;
      let param = {
        guid: this.guid,
      };
      if ((index == 0 || index) && this.estateTypes[index].data) {
        setTimeout(() => {
          this.data = this.estateTypes[index].data;
        }, 500);
        // this.data = this.estateTypes[index].data;
      } else if (index == 0 || index) {
        const { data } = await newEstateService.getHouseTypeDetail(param);
        this.estateTypes[index].data = data;
        this.data = data;
        console.log("getHouseTypeDetail>index>>", index);
      } else {
        const { data } = await newEstateService.getHouseTypeDetail(param);
        this.data = data;
      }

      this.loadBindModel(guid, index);
    },
    //获取经纪人
    async getAgentData() {
      let params = {
        estateGuid: this.$route.query.guid,
        limitCount: 10,
      };
      const { data } = await newEstateService.getAgentData(params);

      this.rcommendAgent = data;
      this.agentData = data[0];
      this.rcommendAgent.forEach((item) => {
        if (item.fixTel&&!this.isSourceERP) {
          item.tell = item.fixTel;
        } else {
          item.tell = item.mobile;
        }
      });
    },
    async loadBindModel(guid, index) {
      let params = {
        houseTypeGuid: guid,
      };
      if ((index || index == 0) && this.estateTypes[index].bindModel) {
        this.bindModel = this.estateTypes[index].bindModel;
        return;
      }
      const { data } = await newEstateService.loadBindModel(params);
      let arr = [];
      if (this.building.length > 0) {
        data.forEach((item) => {
          if (item.level == 1) {
            arr.push(item.no);
          } else {
            this.building.forEach((build) => {
              if (build.guid == item.parentGuid) {
                arr.push(build.no + "-" + item.no);
              }
            });
          }
        });
      } else {
        data.forEach((item) => {
          arr.push(item.no);
        });
      }

      if (index || index == 0) {
        this.estateTypes[index].bindModel = arr.join(",");
      }
      this.bindModel = arr.join(",");
    },
    async loadBuilding() {
      let params = {
        estateGuid: this.estateGuid,
      };
      const { data } = await newEstateService.getBuilding(params);
      this.building = data;
    },
    getHouseTypeDetailToTab(guid) {
      this.getHouseTypes();
      this.getHouseTypeDetail(guid);
    },
  },
  created() {
    this.getHouseTypes();
    this.loadBuilding();

    if (this.$route.query.houseTypeGuid) {
      this.guid = this.$route.query.houseTypeGuid;
      this.getHouseTypeDetail(this.guid);
    }
    this.getAgentData();
  },
  computed: {
    ...mapState(["isSourceERP"])
  },
};
</script>

<style lang="less" scoped>
.tab-item {
  display: inline-block;
  margin-right: 15px;
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  //   text-decoration: underline;
}
.tab-item-buildingArea {
  display: inline-block;
  margin-right: 15px;
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  background: rgb(245, 245, 245);
  color: rgb(107, 112, 114);
  //   text-decoration: underline;
}
.active,
.tab-item:hover {
  background: #666;
  border-radius: 2px;
  cursor: pointer;
  color: #fff;
}
.tab-item-underline {
  height: 2px;
  border-radius: 1px;
}
.estate-box {
  display: flex;
  //   overflow-x: auto;
  flex-wrap: wrap;
}
.estate-item {
  margin: 20px 0;
  flex-shrink: 0;
  width: 309px;
  height: 299px;
  position: relative;
  overflow: hidden;
}
.estate-item:not(:nth-of-type(3n)) {
  margin-right: 40px;
}
.cover-box {
  position: relative;

  .hxt-mask {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000\9;
    filter: alpha(opacity=3);
    background: radial-gradient(
      circle,
      hsla(0, 0%, 100%, 0),
      rgba(0, 0, 0, 0.05)
    );
    z-index: 1;
  }
}

.estate-item-footer {
  padding-top: 20px;
  //   border-top:1px solid #cccccc;
  border-top: none;
  .estate-item-footer-bt {
    height: 36px;
    line-height: 36px;
    text-align: center;
    border: 1px solid #707070;
    border-radius: 2px;
    cursor: pointer;
    color: #9399a5;
  }
}

.houseType-warp {
  .houseType-img {
    position: relative;
    left: 0;
    top: 0;
    background: #f8f8f9;
    img {
      max-height: 761px;
      max-width: 100%;
      vertical-align: middle;
    }
  }
}

.pic-info {
  width: 400px;
  padding: 26px 18px;
  background: #fff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  height: fit-content;
  position: relative;
  box-sizing: border-box;
  .inner-wrapper {
    visibility: visible;
    .frame-name-wrapper {
      position: relative;
      .name-left {
        margin-right: 80px;
        .name {
          display: inline;
          font-size: 26px;
          color: #101d37;
          font-weight: 700;
          line-height: 37px;
          vertical-align: middle;
        }
        .status {
          display: inline-block;
          height: 24px;
          padding: 0 6px;
          line-height: 24px;
          font-size: 14px;
          color: #fff;
          font-weight: 700;
          background-image: linear-gradient(-135deg, #6b99f6, #3072f6);
          border-radius: 2px;
        }
      }
      .name-right {
        position: absolute;
        top: 4px;
        right: 0;
        height: 26px;
        padding: 0 10px;
        line-height: 26px;
        border: 1px solid #9399a5;
        border-radius: 2px;
        font-size: 12px;
        color: #9399a5;
        cursor: pointer;
      }
    }
  }
  .frame-price-wrapper {
    position: relative;
    margin-top: 6px;
    .price-left .price {
      font-size: 26px;
      color: #fe615a;
      line-height: 37px;
      font-weight: 700;
      vertical-align: baseline;
    }
    .price-left .price-status {
      font-size: 16px;
      color: #fe615a;
      line-height: 22px;
      vertical-align: baseline;
    }
    .change-price {
      display: inline-block;
      color: #fe615a;
      height: 18px;
      padding: 0 4px;
      line-height: 18px;
      font-size: 12px;
      background: rgba(254, 97, 90, 0.1);
      border: 1px solid #f76e64;
      border-radius: 1px;
      cursor: pointer;
    }
    .price-right {
      position: absolute;
      top: 3px;
      right: 0;
      text-align: center;
      cursor: pointer;
      .text {
        font-size: 14px;
        color: #101d37;
      }
    }
  }
  .base-info-wrapper {
    padding-top: 29px;
    margin-top: 26px;
    padding-bottom: 13px;
    border-bottom: 1px solid #e4e6f0;
    font-size: 0;
    border-top: 1px solid #e4e6f0;
    .part {
      display: inline-block;
      width: 50%;
      margin-bottom: 13px;
      .key {
        display: inline-block;
        width: 61px;
        height: 20px;
        margin-right: 20px;
        line-height: 20px;
        font-size: 14px;
        color: #9399a5;
        text-align: justify;
        overflow: hidden;
        vertical-align: middle;
      }
      .key:after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 0;
      }
      .value {
        display: inline-block;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        color: #101d37;
        vertical-align: middle;
      }
    }
    .sub-info {
      position: relative;
      margin-bottom: 13px;
      .sub-key {
        position: absolute;
        top: 0;
        left: 0;
        width: 61px;
        height: 20px;
        margin-right: 20px;
        line-height: 20px;
        font-size: 14px;
        color: #9399a5;
        text-align: justify;
        overflow: hidden;
      }
      .sub-key:after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 0;
      }
      .text {
        display: block;
        margin-left: 80px;
        line-height: 20px;
        font-size: 14px;
        color: #101d37;
        letter-spacing: 0;
        vertical-align: top;
      }
    }
  }
  .sub-info-wrapper {
    font-size: 0;
    margin-top: 26px;
    padding-bottom: 13px;
    .sub-info {
      position: relative;
      margin-bottom: 13px;
      .sub-key {
        position: absolute;
        top: 0;
        left: 0;
        width: 61px;
        height: 20px;
        margin-right: 20px;
        line-height: 20px;
        font-size: 14px;
        color: #101d37;
        text-align: justify;
        overflow: hidden;
      }
      .sub-key:after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 0;
      }
      .text {
        display: block;
        margin-left: 80px;
        line-height: 20px;
        font-size: 14px;
        color: #849aad;
        letter-spacing: 0;
        vertical-align: top;
      }
    }
  }
  .frame-tag-wrapper {
    font-size: 0;
    margin-top: 17px;
    height: 24px;
    overflow: hidden;
    .tag {
      display: inline-block;
      height: 24px;
      padding: 0 13px;
      margin-right: 10px;
      line-height: 24px;
      font-size: 12px;
      color: #849aad;
      background: rgba(132, 154, 173, 0.11);
      border-radius: 2px;
    }
  }
}

.info-agent {
  .agent-avatar {
    width: 74px;
    height: 100px;
    // border: 1px solid #707070;
  }
  .agent-name {
    color: #101d37;
    font-weight: bold;
    margin-right: 40px;
  }
}
.agent-tag {
  padding: 0 3px;
  height: 24px;
  line-height: 24px;
  color: #889ebc;
  background-color: #f2f5f7;
  border-radius: 2px;
}
.tButton {
  background: #00ae66;
  color: #fff;
  border-radius: 1px;
  .i {
    color: #fff;
  }
}
.phoneButton:hover {
  background: #f0fef8;
  box-sizing: border-box;
  border: #00ae66 1px solid;
  color: #00ae66;
  .i {
    color: #00ae66;
  }
}
.chatButton {
  background: #f0fef8;
  border-radius: 1px;
  border: #00ae66 1px solid;
  color: #00ae66;
  .i {
    color: #00ae66;
  }
}
.chatButton:hover {
  box-sizing: border-box;
  background: #fff;
  color: #00ae66;
  border: #00ae66 1px solid;

  .i {
    color: #00ae66;
  }
}

.det-disclaimer {
  height: 20px;
  padding: 0 0 52px 16px;
  margin: 0;
  background: url(../../../assets/icon/detail_new_disclaimer.png?dd64809…);

  background-repeat: no-repeat;
  background-position: 0 5px;
  color: #9399a5;
  font-size: 0;
  span {
    font-size: 12px;
    line-height: 20px;
  }
}

@keyframes show {
  0% {
    opacity: 0;
    left: 500px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
    left: 0;
  }
  100% {
    opacity: 0;
    left: -500px;
  }
}
.show-enter-active {
  animation: show 1.5s;
}
.show-leave-active {
  animation: hide 1.5s;
}
.show-enter,
.show-leave-to {
  opacity: 0;
}
</style>