<template>
  <div>
    <div class="grayBG">
      <ul ref="houseTabRef" class="content row grayBG" style="height: 54px">
        <div class="flex1 row s_center">
          <li
            style="line-height: 54px; text-align: center; margin-right: 35px"
            class="size16 relative cursor"
            v-for="tab in houseTabs"
            :key="tab.key"
            @click="onClickTabItem(tab)"
          >
            <a class="col4d bold" :class="{ green: tab.choose }">
              {{ tab.title }}
            </a>
            <div v-show="tab.choose" class="absolute tab-bottom greenBG"></div>
          </li>
        </div>
      </ul>
    </div>
    <div class="estatePlan content">
      <NewEstateInfo :estateGuid="estateGuid" v-if="chooseKey == 6" />
      <NewEstateHouseType :estateGuid="estateGuid" v-if="chooseKey == 2" />
      <PhotoAlbum
        :videoUrl="house.videoUrl"
        :vrUrl="house.vrUrl"
        :coverImage="house.coverImage"
        :estateGuid="estateGuid"
        v-if="chooseKey == 3"
      />
      <div v-if="chooseKey == 5" style="padding: 30px 0">
        <map-peripheral
          titleStyle="color:#000;font-size:20px;font-weight: 400;"
          v-if="house.lat && house.lng"
          :lat="house.lat"
          :lng="house.lng"
          :estateName="house.name"
        />
      </div>
      <div v-if="chooseKey == 7" style="padding: 30px 0">
        <div id="estate-recommend" class="mt70">
          <h1 class="black size20" style="margin-bottom: 30px">楼盘推荐</h1>
          <ul class="flex">
            <li
              class="recommend-item cupo"
              v-for="item in similarityEstate"
              :key="item.guid"
              @click="goDetail(item.guid)"
            >
              <div class="recommend-cover relative">
                <img class="all" :src="item.coverImage" alt="" />
                <div
                  v-if="item.totalPrice"
                  class="absolute recommend-total-price size16 white redBG"
                >
                  总价 {{ item.totalPrice }} 万/套
                </div>
                <div
                  v-else
                  class="absolute recommend-total-price size16 white redBG"
                >
                  价格待定
                </div>
              </div>
              <div class="flex s_center" style="margin-top: 15px">
                <p class="size16 black ellipsis" style="flex: 1">
                  {{ item.title }}
                </p>
                <div class="ml10 tag white greenBG">住宅</div>
              </div>
              <span style="color: #b4b8c1" class="size14"
                >建面{{ item.areas + "㎡" }}</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewEstateInfo from "./components/NewEstateInfo.vue";
import NewEstateHouseType from "./components/NewEstateHouseType.vue";
import PhotoAlbum from "./components/PhotoAlbum.vue";
import MapPeripheral from "../../components/MapPeripheral.vue";
import newEstateService from "../../services/newEstateService";
export default {
  components: {
    NewEstateInfo,
    NewEstateHouseType,
    MapPeripheral,
    PhotoAlbum,
  },
  data() {
    return {
      houseTabs: [
        {
          title: "楼盘首页",
          choose: true,
          key: 1,
          id: "#house-index",
        },
        {
          title: "户型介绍",
          choose: false,
          key: 2,
          id: "#house-type",
        },

        {
          title: "楼盘相册",
          choose: false,
          key: 3,
          id: "#estate-imgs",
        },
        // {
        //   title: "楼盘动态",
        //   choose: false,
        //   key: 4,
        //   id: "#estate-dynamic",
        // },
        {
          title: "周边配套",
          choose: false,
          key: 5,
          id: "#location-nearby",
        },
        {
          title: "详细信息",
          choose: false,
          key: 6,
          id: "#estate-info",
        },
        {
          title: "楼盘推荐",
          choose: false,
          key: 7,
          id: "#estate-recommend",
        },
      ],
      estateGuid: "",
      chooseKey: 0,
      house: {},

      similarityEstate: [],
    };
  },
  methods: {
    onClickTabItem(item) {
      if (item.choose) return;
      if (item.key == 1) {
        this.$router.push({
          name: "new-estate-detail",
          query: { guid: this.estateGuid },
        });
      } else {
        this.$router.push({
          path: "/newEstateTab/" + item.key,
          query: { guid: this.estateGuid },
        });
      }
    },
    getChoose() {
      const { chooseKey } = this.$route.params;
      this.chooseKey = chooseKey;
      this.houseTabs.forEach((ele) => {
        if (chooseKey == ele.key) {
          ele.choose = true;
        } else {
          ele.choose = false;
        }
      });
    },

    async getNewEstateData() {
      const { data } = await newEstateService.getNewEstateData({
        estateGuid: this.estateGuid,
      });
      this.house = data.realHouseEstateVo;
    },
    //相似楼盘
    async getSimilarityEstate() {
      let params = {
        cityCode: this.$store.state.location.cityCode,
        estateGuid: this.estateGuid,
      };
      const { data } = await newEstateService.getSimilarityEstate(params);
      // console.log('getSimilarityEstate', data)
      data.forEach((item, index) => {
        if (index < 4) {
          this.similarityEstate.push(item);
        }
      });
    },
    goDetail(guid) {
      // console.log(guid)
      this.$router.push({
        name: "new-estate-detail",
        query: { guid: guid },
      });
    },
  },
  watch: {
    $route(newVal, val) {
      if (newVal.params.chooseKey !== val.params.chooseKey) {
        this.chooseKey = newVal.params.chooseKey;
        this.houseTabs.forEach((ele) => {
          if (newVal.params.chooseKey == ele.key) {
            ele.choose = true;
          } else {
            ele.choose = false;
          }
        });
      }
    },
  },
  created() {
    this.estateGuid = this.$route.query.guid;
    this.getChoose();
    this.getNewEstateData();
    this.getSimilarityEstate();
  },
};
</script>

<style lang="less" scoped>
.tab-bottom {
  width: 50%;
  left: 50%;
  bottom: 0;
  height: 3px;
  transform: translateX(-50%);
}
.tab-item {
  margin-right: 54px;
  //   text-decoration: underline;
}
.bold {
  font-weight: bold;
}
.estatePlan {
  // width: 100%;
  // height: 100%;
}
.recommend-item {
  width: 267px;
  margin-bottom: 40px;
}
.recommend-item:not(:nth-of-type(4n)) {
  margin-right: 30px;
}
.recommend-cover {
  height: 220px;
}
.recommend-total-price {
  bottom: 20px;
  left: 0;
  padding: 0 5px;
  height: 25px;
  line-height: 25px;
}
.cupo {
  cursor: pointer;
}
</style>