<template>
  <div class="estateInfo row">
    <div class="row-left">
      <h2
        class="x-title"
        style="margin-top: 6px"
      >基本信息</h2>
      <ul class="x-box">
        <li>
          <span class="label">物业类型：</span>
          <span class="label-val">{{ data.propertyTypeDesc }}</span>
        </li>
        <li>
          <span class="label">参考价格：</span>
          <span class="label-val">{{
            data.averagePrice ? data.averagePrice + " 元/m²" : ""
          }}</span>
        </li>
        <li>
          <span class="label">装修标准：</span>
          <span class="label-val">{{ data.decorateDesc }}</span>
        </li>

        <li class="">
          <span class="label">建筑类型</span>
          <span class="label-val">{{ data.buildingTypeDesc }}</span>
        </li>
        <li class="">
          <span class="label">产权年限</span>
          <span class="label-val">{{ data.useLimitDesc }}</span>
        </li>
        <li class="all-row">
          <span class="label">开发商：</span>
          <span class="label-val">{{ data.devCompany }}</span>
        </li>
        <li class="all-row">
          <span class="label">所在区域：</span>
          <span class="label-val">{{
            data.geographicAreaName ? data.geographicArea.join("/") : ""
          }}</span>
        </li>
        <li
          class="all-row"
          v-if="data.address"
        >
          <span class="label">楼盘地址：</span>
          <span class="label-val">{{ data.address }}</span>
        </li>
        <li
          class="all-row"
          v-if="data.transportation"
        >
          <span class="label">交通配套：</span>
          <span class="label-val">{{ data.transportation }}</span>
        </li>
        <li
          class="all-row"
          v-if="data.education"
        >
          <span class="label">教育资源：</span>
          <span class="label-val">{{ data.education }}</span>
        </li>
        <li
          class="all-row"
          v-if="data.medicalHealth"
        >
          <span class="label">医疗设施：</span>
          <span class="label-val">{{ data.medicalHealth }}</span>
        </li>
        <li
          class="all-row"
          v-if="data.peripherals"
        >
          <span class="label">生活购物：</span>
          <span class="label-val">{{ data.peripherals }}</span>
        </li>
        <li
          class="all-row"
          v-if="data.brightSpot"
        >
          <span class="label">亮点：</span>
          <span class="label-val">{{ data.brightSpot }}</span>
        </li>
      </ul>
      <div class="x-line"></div>
      <h2
        class="x-title"
        style="margin-top: 6px"
      >物业信息</h2>
      <ul class="x-box">
        <li>
          <span class="label">占地面积：</span>
          <span class="label-val">{{
            property.floorArea ? property.floorArea + " m²" : ""
          }}</span>
        </li>
        <li>
          <span class="label">建筑面积：</span>
          <span class="label-val">{{
            property.buildArea ? property.buildArea + " m²" : ""
          }}</span>
        </li>
        <li>
          <span class="label">规划楼栋：</span>
          <span class="label-val">{{
            property.buildingNumber ? property.buildingNumber + " 栋" : ""
          }}</span>
        </li>
        <li>
          <span class="label">规划户数：</span>
          <span class="label-val">{{
            property.totalHouse ? property.totalHouse + " 户" : ""
          }}</span>
        </li>
        <li class="">
          <span class="label">规划车位：</span>
          <span class="label-val">{{
            property.carport ? property.carport + " 个" : ""
          }}</span>
        </li>
        <li class="">
          <span class="label">物业公司：</span>
          <span class="label-val">{{
            property.managementCompany ? property.managementCompany : ""
          }}</span>
        </li>
        <li class="">
          <span class="label">物业费用：</span>
          <span class="label-val">{{
            property.managementPrice ? property.managementPrice + " 元/m²" : ""
          }}</span>
        </li>
        <li class="">
          <span class="label">供暖方式：</span>
          <span class="label-val">{{ property.heatingTypeDesc }}</span>
        </li>
        <li class="">
          <span class="label">容积率：</span>
          <span class="label-val">{{
            property.plotRatio ? property.plotRatio : ""
          }}</span>
        </li>
        <li class="">
          <span class="label">绿化率：</span>
          <span class="label-val">{{
            property.greeningRate ? property.greeningRate + " %" : ""
          }}</span>
        </li>
        <li class="">
          <span class="label">供水：</span>
          <span class="label-val">{{ property.waterSupplyTypeDesc }}</span>
        </li>
        <li class="">
          <span class="label">供电</span>
          <span class="label-val">{{ property.powerSupplyTypeDesc }}</span>
        </li>
      </ul>
      <div class="x-line"></div>
      <h2
        class="x-title"
        style="margin-top: 6px"
      >销售信息</h2>
      <ul class="x-box">
        <li>
          <span class="label">开盘时间：</span>
          <span class="label-val">{{ sellInfo.firstSellTime | datetime }}</span>
        </li>
        <li>
          <span class="label">最近开盘：</span>
          <span class="label-val">{{
            sellInfo.recentlySellTime | datetime
          }}</span>
        </li>
        <li>
          <span class="label">最近交房：</span>
          <span class="label-val">{{
            sellInfo.recentlyDeliveryTime | datetime
          }}</span>
        </li>
        <li>
          <span class="label">销售状态：</span>
          <span class="label-val">{{ sellInfo.sellStateDesc }}</span>
        </li>
        <li class="">
          <span class="label">联系电话：</span>
          <span class="label-val">{{ sellInfo.phone }}</span>
        </li>
        <li class="">
          <span class="label">负责人：</span>
          <span class="label-val">{{ sellInfo.principal }}</span>
        </li>
        <li class="all-row">
          <span class="label">售楼地址：</span>
          <span class="label-val">{{ sellInfo.sellAddress }}</span>
        </li>
      </ul>
    </div>
    <div class="flex1"></div>
    <div
      class="row-right"
      style=""
    >
      <div class="photowrap">
        <h5 style="margin-top: 7px">地理位置</h5>
        <baidu-map
          :center="{ lat: this.lat, lng: this.lng }"
          :zoom="16"
          @ready="loadMap"
          style="height: 150px; width: 200px"
          :scroll-wheel-zoom="true"
        >
          <bm-view style="width: 200px; height: 150px"></bm-view>

          <!-- <bm-label :offset="{width:5,height:-5}"
                          :massClear="false"
                          :labelStyle="{border:'none',padding:0,background:'none'}"
                          :content="titleLabel"
                          :position="{lng:lng, lat:lat}" /> -->
          <bm-overlay
            pane="labelPane"
            class="zone"
            @draw="draw"
          >
            <div style="
                width: 8px;
                height: 8px;
                background: #00ae66;
                border: 2px #fff solid;
                border-radius: 6px;
                position: relative;
                left: 0;
                top: 0;
              ">
              <div style="
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 22px;
                  color: #ffffff;
                  padding: 0px 26px;
                  position: absolute;
                  left: -300%;
                  bottom: 17px;
                  width: max-content;
                ">
                <div style="
                    background: #00ae66;
                    position: relative;
                    left: -50%;
                    padding: 9px 26px;
                  ">
                  <span>{{ data.name }}</span>

                  <div style="
                      width: 0;
                      height: 0;
                      border-left: 7px solid transparent;
                      border-right: 7px solid transparent;
                      border-top: 12px solid #00ae66;
                      position: absolute;
                      left: 50%;
                      margin-left: -5px;
                      bottom: -6px;
                    "></div>
                </div>
              </div>
            </div>
          </bm-overlay>
          <!-- <bm-marker :position="mPoint"></bm-marker> -->
        </baidu-map>
      </div>
      <div class="photowrap">
        <h5
          style="margin-top: 7px"
          class="row s_end"
        >
          楼盘相册
          <router-link
            :to="{
              path: '/newEstateTab/3',
              query: {
                guid: this.estateGuid,
              },
            }"
            class="img-button row s_center size14"
            style="color: #ccc; margin-left: 20px"
          >查看全部相册</router-link>
        </h5>
        <router-link
          :to="{
            path: '/newEstateTab/3',
            query: {
              guid: this.estateGuid,
            },
          }"
          style="color: #ccc"
        >
          <img
            :src="data.coverImage"
            style="width: 200px; height: 160px"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import newEstateService from "../../../services/newEstateService";

export default {
  data() {
    return {
      ak: "cgR5juzHQGCiXxUutZqKf82L93QIONiG",
      data: {},
      property: {},
      sellInfo: {},
      licence: {},
      lat: "",
      lng: "",
      mPoint: "",
    };
  },
  props: ["estateGuid"],
  methods: {
    loadMap({ BMap, map }) {
      this.BMap = BMap;
      this.map = map;
      this.mPoint = new BMap.Point(this.lng, this.lat);
    },
    async getNewEstateData() {
      const { data } = await newEstateService.getNewEstateData({
        estateGuid: this.estateGuid,
      });
      //   console.log("getNewEstateData >>", data);
      const { lat, lng } = data.realHouseEstateVo;
      this.data = data.realHouseEstateVo;
      //   console.log("getNewEstateData >>", data.realHouseEstateVo);
      this.lat = lat;
      this.lng = lng;
      this.property = data.realHouseEstateMgtVo;
      this.sellInfo = data.realHouseEstateSellInfoVo;
      this.licence = data.realHouseEstateSellLicenceVo;
    },
    draw({ BMap, map, el }) {
      const pixel = map.pointToOverlayPixel(new BMap.Point(this.lng, this.lat));
      el.style.left = pixel.x - 4 + "px";
      el.style.top = pixel.y - 4 + "px";
    },
  },
  created() {
    this.getNewEstateData();
  },
};
</script>

<style lang="less" scoped>
.row-left {
  width: 800px;
}
.x-title {
  font-size: 20px;
  line-height: 78px;
}
.x-box li {
  display: inline-block;
  width: 48%;
  margin-bottom: 10px;
  .label {
    display: inline-block;
    width: 100px;
    font-weight: 700;
    color: #333;
    vertical-align: top;
  }
  .label-val {
    color: #888;
  }
}
.x-box .all-row {
  display: block;
  width: 100%;
}
.x-line {
  width: 100%;
  height: 1px;
  background-color: #eee;
  margin-top: 35px;
  margin-bottom: 10px;
}

.row-right {
  height: 200px;
}
.zone {
  width: 1px;
  height: 1px;
  background: #00ae66;
  border: 0px #fff solid;
  border-radius: 0px;
  position: relative;
  left: 0;
  top: 0;
}

.photowrap h5 {
  text-align: left;
  padding: 20px 0;
  color: #333;
  font-size: 16px;
  font-weight: 700;
  position: relative;
}
</style>